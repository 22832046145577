
import EquipmentsSelect from "@/components/catalogs-select/EquipmentsSelect.vue";
import EntitySelect from "@/components/catalogs-select/EntitySelect.vue";
import { defineComponent, onMounted, onUpdated, ref } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";
import EntitySelectForOperator from "@/components/catalogs-select/EntitySelectForOperator.vue";
import InternationalCodeSelect from "@/components/catalogs-select/InternationalCodesSelect.vue";
import {useStore} from "vuex";

interface FormCatalog {
  id?: number;
  registration_number: string;
  equipment_id: number | null;
  client_id: number | null;
  owner_id: number | null;
  manufaturing_date: "";
  tare: "";
  pmm: "";
  international_code: number | null;
  manufacturing_materials: "";
}

export default defineComponent({
  name: "RegisteredEquipmentForm",
  components: {
    InternationalCodeSelect,
    EntitySelectForOperator,
    Form,
    Field,
    ErrorMessage,
    EquipmentsSelect,
    EntitySelect,
  },
  props: {
    entityId: String,
  },

  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const submitButton = ref<HTMLElement | null>(null);
    const form = ref<FormCatalog>({
      registration_number: "",
      equipment_id: null,
      client_id: null,
      owner_id: null,
      manufaturing_date: "",
      tare: "",
      pmm: "",
      international_code: null,
      manufacturing_materials: "",
    });

    const isNameRequired = (value) => {
      if (!value) {
        return t("rname");
      }
      return true;
    };

    onUpdated(() => {
      console.clear();
    });

    onMounted(() => {
      console.clear();
      if (props.entityId) {
        store.commit("setLoadingStatus", true);
        ApiService.get("/api/registered-equipment/" + props.entityId).then(
          ({ data }) => {
            form.value = { ...data };
            store.commit("setLoadingStatus", false);
          }
        );
      }
    });

    const createElement = async (resetForm) => {
      try {
        store.commit("setLoadingStatus", true);
        const resp = (
          await ApiService.post("/api/registered-equipment", form.value)
        ).data;
        emit("success", resp);
        resetForm();
        submitButton.value?.removeAttribute("data-kt-indicator");
      } catch (error) {
        store.commit("setLoadingStatus", false);
        submitButton.value?.removeAttribute("data-kt-indicator");
      }
    };

    const updateElement = (resetForm) => {
      store.commit("setLoadingStatus", true);
      ApiService.put("/api/registered-equipment/" + props.entityId, form.value)
        .then(({ data }) => {
          emit("success", data);
          submitButton.value?.removeAttribute("data-kt-indicator");
          form.value = data;
          resetForm();
        })
        .catch(() => {
          store.commit("setLoadingStatus", false);
          submitButton.value?.removeAttribute("data-kt-indicator");
        });
    };

    const onSumbit = (value, { resetForm }) => {
      if (submitButton.value) {
        submitButton.value.setAttribute("data-kt-indicator", "on");
        if (form.value.id) {
          updateElement(resetForm);
        } else {
          createElement(resetForm);
        }
      }
    };

    return {
      submitButton,
      form,
      onSumbit,
      isNameRequired,
      updateElement,
      createElement,
    };
  },
});
