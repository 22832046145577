
import { computed, onMounted, ref, watch } from "vue";
import ApiService from "../../core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import { useStore } from "vuex";

export default {
  name: "InternationalCodeSelect",
  props: {
    international_code_id: {
      type: [Number, Array],
      default: undefined,
    },
    showLabel: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "small",
    },
    required: {
      type: Boolean,
      default: true,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:international_code_id", "changeInternationalCode"],

  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const internationalCodeData = computed(
      () => store.getters.internationalCode
    );
    const code = ref("");
    const inputInternationalCode = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });

    const isInternationalCodeIdRequired = (value) => {
      if (props.required && !value) {
        return t("internationalCodeid");
      }
      return true;
    };

    const {
      errorMessage,
      value: element_id,
      meta,
    } = useField("incoterm_type", isInternationalCodeIdRequired);

    const getInternationalCode = () => {
      /*if (!internationalCodeData.value.length) {
        ApiService.get("/api/internationalCodes/lists").then(({ data }) => {
          store.commit("setInternationalCode", data.international_codes);
          inputInternationalCode.value.list = data.international_codes;
          inputInternationalCode.value.options = data.international_codes;
        });
      } else {
        inputInternationalCode.value.list = internationalCodeData.value;
        inputInternationalCode.value.options = internationalCodeData.value;
      }*/
      ApiService.get("/api/internationalCodes/lists").then(({ data }) => {
        store.commit("setInternationalCode", data.international_codes);
        inputInternationalCode.value.list = data.international_codes;
        inputInternationalCode.value.options = data.international_codes;
      });
      if (props.multiselect) {
        inputInternationalCode.value.options.splice(0, 0, {
          id: 0,
          name: "Todos",
        });
      }
    };
    const selectInternationalCode = (query) => {
      if (query !== "") {
        inputInternationalCode.value.loading = true;
        setTimeout(() => {
          inputInternationalCode.value.loading = false;
          inputInternationalCode.value.options =
            inputInternationalCode.value.list.filter(
              (item: Record<string, any>) => {
                return (
                  item.name.toLowerCase().indexOf(query.toLowerCase()) > -1
                );
              }
            );
          if (!inputInternationalCode.value.options.length) {
            ApiService.query(`/api/internationalCodes/lists`, {
              params: { per_page: 25, name: query },
            }).then(({ data }) => {
              inputInternationalCode.value.list =
                data.international_codes.name;
              inputInternationalCode.value.options =
                data.international_codes.name;
            });
          }
        }, 200);
      } else {
        inputInternationalCode.value.options = [];
      }
    };

    watch(
      () => props.international_code_id,
      (first) => {
        if (first) {
          element_id.value = first;
          const temp = inputInternationalCode.value.options.filter(
            (item: Record<string, "">) => {
              return item.id === first;
            }
          );
          if (!props.multiselect) {
            code.value = temp[0]["name"];
            emit("changeInternationalCode", code.value);
          }
        }
      }
    );

    onMounted(() => {
      getInternationalCode();
    });

    return {
      element_id,
      inputInternationalCode,
      errorMessage,
      meta,
      getInternationalCode,
      selectInternationalCode,
    };
  },
};
